$(document).ready(function(){

  if($(".navbar")[0] != undefined){
    navbar()
  }
  whichPage()
})

var open = false

function navbar(){
  $('[data-id="burger"]').on('click', function(){
    open = !open
    open ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"
    $(this).toggleClass("X")
    $('[data-id="menu"]').toggleClass("animated slideInRight active")
  })
}

function whichPage(){
  console.log($('main').attr('data-id'))
  var thisPage = $('main').attr('data-id')
  $('a[data-id='+ thisPage +']').addClass('active')
}

// RWD
// $(window).resize(function() {
//     if ($(window).width() <= 768) {
//       $('[data-id="menu"]').hide()
//   } else {
//     $('[data-id="menu"]').css('display','flex')
//   }
// });